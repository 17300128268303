import * as React from 'react'
import { ChangeEventHandler } from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  FormTab,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useGetIdentity,
  useResourceContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import FormGroup from 'src/components/FormGroup'
import { FacilityTypeList } from 'src/types/enum/FacilityType'
import { UnitStatusList } from 'src/types/enum/UnitStatus'
import Formatter from 'src/utils/formatters'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { MultiEmailInput } from 'src/components/inputs/MultiEmailInput'
import { MediaInput } from 'src/components/inputs/MediaInput'
import { PriceRatingList } from 'src/types/enum/PriceRating'
import WorkScheduleMultiTimeInput from 'src/components/inputs/WorkScheduleMultiTimeInput'
import { RestaurantMenuItemList } from 'src/resources/restaurant/menu/RestaurantMenuItemList'
import { RichTextInput } from 'src/components/inputs/RichTextInput'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'
import { ServiceTypeDictionary } from 'src/utils/ServiceTypeDictionary'
import { MediaOrderInput } from 'src/components/inputs/MediaOrderInput'
import { RecordChanges } from 'src/components/RecordChangeList'
import { RecordType } from 'src/components/RecordChangeList'
import { PromotionTypeList } from '../../types/enum/PromotionType'
import CountryInput from '../../components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'

const InfoTab = (props: any) => {
  const resource = useResourceContext()
  const form = useFormContext()
  const { data: user } = useGetIdentity()
  //
  const formValues = form.watch()

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
    form.setValue('innerName', e.currentTarget.value)
  }

  return (
    <FormTab {...props} label={'Заведение'}>
      <FormGroup title={'Данные'}>
        <TextInput
          source="name"
          label={'Название'}
          validate={[required()]}
          fullWidth
          onChange={handleNameChange}
          helperText={'Может выводиться в каталоге'}
        />
        <TextInput
          source="innerName"
          label={'Внутреннее название'}
          fullWidth
          onChange={handleNameChange}
          helperText={'Выводиться только в ЛК Партнера'}
        />
        <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
        <IfCanAccess source={'status'} label={'Статус'} aggregator action={'edit'}>
          <SelectInput source={'status'} label={'Статус'} choices={UnitStatusList} validate={[required()]} />
        </IfCanAccess>

        <TextInput source="vendorCode" label={'Код у бренда'} fullWidth />
        <CountryInput
          source={'countryId'}
          label={'Страна'}
          allowEmpty={false}
          onChange={() => form.resetField('regionId')}
          fullWidth
          validate={required()}
        />
        <PartnerInput
          source={'partnerId'}
          label={'Партнер'}
          filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
          validate={[required()]}
        />
        <SelectInput
          source="priceRating"
          label={'Сегмент'}
          choices={PriceRatingList}
          validate={[required()]}
          fullWidth
        />
        <NumberInput source="averageBill" label={'Средний чек'} fullWidth />
        <TextInput source="rating" label={'Рейтинг'} fullWidth />
        <RadioButtonGroupInput
          source="facilityType"
          label={'Тип заведения'}
          choices={FacilityTypeList}
          row={false}
          validate={[required()]}
        />
        <SelectInput
          source="activePromotionType"
          label={'Тип активных акциий'}
          choices={PromotionTypeList}
          validate={[required()]}
        />
        <BooleanInput source="autoConfirmReservations" label={'Автоматическое подтверждение брони'} />
        <BooleanInput source="published" label={'Видимость'} fullWidth />
        <RichTextInput source="about" label={'Описание'} fullWidth />
      </FormGroup>
      <FormGroup title={'Товарные разделы'}>
        <CategoriesArrayInput
          filter={{
            ...getCountryIdFilterBasedOnProject(formValues.countryId),
            serviceType: ServiceTypeDictionary.convertResource(resource),
          }}
          source={'categoriesIds'}
          label={'Выбранные разделы'}
        />
      </FormGroup>

      <FormGroup title={'Медиа'}>
        <MediaInput source={'image'} label={'Обложка'} />
        <MediaOrderInput source={'photos'} isMulti={true} label={'Фотографии'} />
      </FormGroup>
      <FormGroup title={'Адрес'}>
        <ReferenceInput
          allowEmpty={false}
          source="regionId"
          reference="region"
          filterToQuery={(searchText: string) => ({ ...(searchText ? { 'name||$eq': searchText } : {}) })}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={100}
        >
          <AutocompleteInput optionText={(i) => `${i?.name || ''}`} label="Город" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="address" label={'Адрес'} validate={[required()]} fullWidth />
        <TextInput
          source="location"
          label={'Координаты'}
          validate={[required()]}
          helperText={'Через запуятую, например 55.616351,36.431444'}
          parse={(value) => {
            const parts = value.split(',')
            if (parts.length < 2 || !parts[0] || !parts[1]) {
              return value
            }
            return { lat: parts[0].trim(), lng: parts[1].trim() }
          }}
          format={(value) => {
            if (value?.lat && value.lng) {
              return `${value.lat},${value.lng}`
            }
            return value
          }}
          multiline={true}
          variant={'outlined'}
          fullWidth={true}
        />
      </FormGroup>
      <FormGroup title={'Контакты'}>
        <TextInput source="contactPerson" label={'Контактное лицо'} fullWidth />
        <TextInput source="contactsPhone" label={'Номер телефона'} fullWidth />
        <TextInput source="phone" label={'Номер телефона (публичный)'} fullWidth />
        <MultiEmailInput source={'contactEmails'} label={'Перечень email для уведомлений'} />
        <TextInput source={'managerName'} label={'Имя и фамилия менеджера, подключившего заведение'} />
      </FormGroup>
      <FormGroup title={'Режим работы'}>
        <WorkScheduleMultiTimeInput source={'schedule'} label={false} />
      </FormGroup>
      {props.isEdit && (
        <RecordChanges
          recordType={RecordType.Restaurant}
          statusList={UnitStatusList}
          additionalAssetsFields={['image', 'imageAdded', 'imageRemoved']}
          additionalMap={[
            { key: 'slug', label: 'URI' },
            { key: 'priceRating', label: 'Сегмент', choices: PriceRatingList },
            { key: 'contactEmails', label: 'Перечень email для уведомлений' },
            { key: 'contactsPhone', label: 'Номер телефона (публичный)' },
            { key: 'managerName', label: 'Менеджер подключивший заведение' },
            { key: 'vendorCode', label: 'Код у бренда' },
            { key: 'averageBill', label: 'Средний чек' },
            { key: 'sort', label: 'Приоритет в выдаче списка ресторанов' },
            { key: 'activePromotionType', label: 'Тип активных акциий', choices: PromotionTypeList },
            { key: 'autoConfirmReservations', label: 'Автоматическое подтверждение брони' },
            { key: 'image', label: 'Обложка' },
            { key: 'imageAdded', label: 'Добавлена обложка' },
            { key: 'imageRemoved', label: 'Удалена обложка' },
            {
              key: 'categories',
              label: 'Товарные разделы',
              valueBuilder: (value): string => {
                if (Array.isArray(value)) {
                  return value.map((el) => el.name).join(', ')
                }
                return value
              },
            },
            {
              key: 'categories',
              label: 'Товарные разделы',
              valueBuilder: (value): string => {
                if (Array.isArray(value)) {
                  return value.map((el) => el.name).join(', ')
                }
                return value
              },
            },
            {
              key: 'schedule',
              label: 'Режим работы',
              valueBuilder: Formatter.formatScheduleForRender,
            },
          ]}
        />
      )}
    </FormTab>
  )
}

const MenuTab = (props: any) => {
  return (
    <FormTab {...props} label={'Меню'}>
      <RestaurantMenuItemList {...props} />
    </FormTab>
  )
}

const RestaurantForm = (props: any) => {
  return (
    <TabbedForm defaultValues={{}}>
      <InfoTab isEdit={props.isEdit} />
      {props.isEdit && <MenuTab />}
    </TabbedForm>
  )
}
export default RestaurantForm

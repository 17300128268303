import * as React from 'react'
import Formatter from 'src/utils/formatters'
import {
  Show,
  FunctionField,
  Labeled,
  EditButton,
  SimpleShowLayout,
  TextField,
  SelectField,
  DateField,
  Pagination,
  ReferenceManyField,
  TopToolbar,
} from 'react-admin'
import * as _ from 'lodash'
import { ReactElement, useMemo } from 'react'
import {
  AllOrdersStatusesList,
  DeliveryOrderStatusesDidntSentForDelivery,
  OrderStatusesFinished,
} from 'src/types/enum/OrderStatus'
import { PaymentStatus, PaymentStatusList } from 'src/types/enum/PaymentStatus'
import { PaymentMethodList } from 'src/types/enum/PaymentMethod'
import Grid from '@mui/material/Grid'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Divider from '@mui/material/Divider'
import { useRecordContext } from 'ra-core'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { OrderStatusChip } from 'src/components/chips/OrderStatusChip'
import styles from './index.module.scss'
import LinkExternalField from 'src/components/fields/LinkExternalField'
import LinkField from 'src/components/fields/LinkField'
import { DeliveryMethodList } from 'src/types/enum/DeliveryMethod'
import RelatedList from 'src/components/list/RelatedList'
import OrderChangedDataField from 'src/components/fields/OrderChangedDataField'
import OrderChangedWhatField from 'src/components/fields/OrderChangedWhatField'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import { ModalButton } from 'src/components/Modal/ModalButton'
import PauseIcon from '@mui/icons-material/Pause'
import { OrderRefundCreateModal } from 'src/resources/order/OrderRefundCreateModal'
import AssetField from 'src/components/fields/AssetField'
import { DropdownMenu } from 'src/components/DropdownMenu/DropdownMenu'
import { ApiIntegrationSyncType } from 'src/types/enum/ApiIntegrationSyncType'
import OrderSyncApiButton from 'src/resources/order/OrderSyncApiButton'
import PhoneCallActionField from 'src/components/fields/PhoneCallActionField'
import { Plus, Pen } from 'mdi-material-ui'
import { ComplaintCreateModal } from 'src/resources/complaint/ComplaintCreateModal'
import UnitLinkField from 'src/components/fields/UnitLinkField'
import { EditOrderModal } from '../ChangeOrderModal'
import { OrderType } from '../../../types/enum/OrderType'

const PaymentErrorDetailsField = () => {
  const record = useRecordContext()

  if (
    (record.paymentStatus !== PaymentStatus.Error && record.paymentStatus !== PaymentStatus.RefundError) ||
    _.isEmpty(record.payments)
  ) {
    return null
  }
  record.payments.sort(
    (payment1: any, payment2: any) => new Date(payment1.createdAt).getTime() - new Date(payment2.createdAt).getTime(),
  )
  //
  const payment = record.payments[0]

  if (_.isNil(payment.actionCode)) {
    return null
  }
  return (
    <Grid item md={3}>
      <Labeled label="Детали ошибки оплаты">
        <Typography component="div" variant="body2" whiteSpace={'pre-wrap'}>
          {!_.isNil(payment.actionCode) && (
            <span>
              <b>Код:</b> {payment.actionCode + '\n'}
            </span>
          )}
          {!_.isNil(payment.actionCodeDescription) && (
            <span>
              <b>Описание:</b> {payment.actionCodeDescription + '\n'}
            </span>
          )}
          {!_.isNil(payment.actionCodeDetails) && (
            <span>
              <b>Детали:</b> {payment.actionCodeDetails + '\n'}
            </span>
          )}
          {!_.isNil(payment.actionCodeAdvice) && (
            <span>
              <b>Совет:</b> {payment.actionCodeAdvice + '\n'}
            </span>
          )}
        </Typography>
      </Labeled>
    </Grid>
  )
}

const ActionFields = () => {
  const record = useRecordContext()

  return (
    <DropdownMenu>
      <OrderSyncApiButton buttonView={'menuItem'} syncType={ApiIntegrationSyncType.OrderSend} orderId={record.id} />
      <OrderSyncApiButton buttonView={'menuItem'} syncType={ApiIntegrationSyncType.OrderStatus} orderId={record.id} />
    </DropdownMenu>
  )
}

const Actions = () => {
  const record = useRecordContext()
  //
  const isTakeaway = useMemo(() => record.type === OrderType.Takeaway, [record])

  return (
    <TopToolbar>
      {OrderStatusesFinished.includes(record.status) &&
        ![
          PaymentStatus.RejectRegistered,
          PaymentStatus.RefundRegistered,
          PaymentStatus.Refund,
          PaymentStatus.Refunded,
        ].includes(record.paymentStatus) && (
          <ModalButton label={'Возврат'} icon={<PauseIcon />} modal={<OrderRefundCreateModal />} />
        )}

      <IfCanAccess aggregator action={'edit'}>
        <Box ml={3}>
          <ModalButton
            label={'Жалоба'}
            icon={<Plus />}
            key={'import'}
            modal={<ComplaintCreateModal orderId={record.id} unitId={record.unitId} userId={record.userId} />}
          />
        </Box>
        <Box ml={3}>
          <EditButton />
        </Box>
        {!isTakeaway &&
          DeliveryOrderStatusesDidntSentForDelivery.includes(record.status) &&
          ![
            PaymentStatus.RejectRegistered,
            PaymentStatus.RefundRegistered,
            PaymentStatus.Refund,
            PaymentStatus.Refunded,
          ].includes(record.paymentStatus) && (
            <Box ml={3}>
              <ModalButton
                label={'Изменить адрес'}
                icon={<Pen />}
                key={'address'}
                modal={<EditOrderModal order={record} />}
              />
            </Box>
          )}
        <Box ml={3}>
          <ActionFields />
        </Box>
      </IfCanAccess>
    </TopToolbar>
  )
}

const OrderLines = () => {
  const record = useRecordContext()

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableBody>
          {record?.lines?.map((item: any) => {
            return (
              <TableRow key={item.id}>
                <TableCell>
                  {item.quantity} x {item.product?.name}
                </TableCell>
                <TableCell align="right"> {Formatter.formatPrice(item.price)}</TableCell>
                <TableCell align="right">{Formatter.formatPrice(item.total)}</TableCell>
              </TableRow>
            )
          })}
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">
              <strong>Скидка</strong>
            </TableCell>
            <TableCell align="right">{Formatter.formatPrice(record.totalDiscount)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">
              <strong>Доставка</strong>
            </TableCell>
            <TableCell align="right">{Formatter.formatPrice(record.totalDelivery)}</TableCell>
          </TableRow>
          {record.publicSurcharges?.map((i: any) => (
            <TableRow key={i.code}>
              <TableCell></TableCell>
              <TableCell align="right">
                <strong>{i.description}</strong>
              </TableCell>
              <TableCell align="right">{Formatter.formatPrice(i.price)}</TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">
              <strong>Сервисный сбор</strong>
            </TableCell>
            <TableCell align="right">{Formatter.formatPrice(record.serviceFee)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">
              <strong>Итого</strong>
            </TableCell>
            <TableCell align="right">{Formatter.formatPrice(record.total)}</TableCell>
          </TableRow>

          {record.moneyChange > 0 && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Сдача с</TableCell>
              <TableCell align="right">{Formatter.formatPrice(record.moneyChange)}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const Header = (props: { children: ReactElement | string }) => {
  return (
    <Box mt={2}>
      <Typography variant="subtitle1">{props.children}</Typography>
      <Divider color={'#dbdbdc'} />
    </Box>
  )
}

const HeaderSection = () => {
  const record = useRecordContext()

  return (
    <div className={styles.headingSection}>
      <AssetField source={'brand.logo'} />
      <div className={styles.info}>
        <div className={styles.subTitle}>
          <Typography variant="h6">Заказ № {record.number ?? record.id}</Typography>
          <OrderStatusChip status={record.status} />
        </div>
        <DateField source="createdAt" label={'Создан'} showTime={true} />
      </div>
    </div>
  )
}

const PreOrder = () => {
  const record = useRecordContext()

  if (!record.isPreOrder) {
    return null
  }
  return (
    <Grid item md={3}>
      <Labeled label="Предзаказ">
        <DateField source="preOrderAt" label={'Предзаказ'} showTime={true} />
      </Labeled>
    </Grid>
  )
}

const OrderChanges = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="order-change"
      target="orderId"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'orders'}
        hasCreate={false}
        emptyTitle={'Пока нет изменений'}
        bulkActionButtons={false}
      >
        <OrderChangedWhatField source={'source'} label={''} />
        <OrderChangedDataField source={'before'} label={'До'} />
        <OrderChangedDataField source={'after'} label={'После'} />
        <DateField source="createdAt" label={'Время'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const OrderShow = () => {
  const record = useRecordContext()
  //
  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <HeaderSection />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Grid container spacing={2}>
            <IfCanAccess aggregator action={'show'}>
              <Grid item md={3}>
                <Labeled label="Партнер">
                  <LinkField link={(order) => `/partner/${order.partnerId}`} source={'partner.name'} />
                </Labeled>
              </Grid>
            </IfCanAccess>
            <Grid item md={3}>
              <Labeled label="№ партнера">
                <TextField source={'partnerNumber'} />
              </Labeled>
            </Grid>
            <Grid item md={3}>
              <Labeled label="Бренд">
                <LinkField link={(order) => `/brand/${order.brandId}`} source={'brand.name'} />
              </Labeled>
            </Grid>
            <Grid item md={3}>
              <Labeled label="Заведение">
                <UnitLinkField source={'unit.name'} withPhone={true} />
              </Labeled>
            </Grid>
          </Grid>

          <div>
            <Labeled label="Комментарий клиента">
              <TextField source={'clientComment'} />
            </Labeled>
          </div>
          <div>
            <Labeled label="Комментарий">
              <TextField source={'adminComment'} />
            </Labeled>
          </div>
          <Header>Состав заказа</Header>
          <OrderLines />
        </Grid>

        <Grid item md={6}>
          <Grid container spacing={2}>
            <PreOrder />
            <Grid item md={2}>
              <Labeled label="Статус">
                <SelectField source={'status'} label={'Статус'} choices={AllOrdersStatusesList} />
              </Labeled>
            </Grid>
            <Grid item md={3}>
              {record.cancelReasonText && (
                <Labeled label="Причина отмены">
                  <TextField source={'cancelReasonText'} label={'Причина отмены'} />
                </Labeled>
              )}
              {record.cancelReason && !record.cancelReasonText && (
                <Labeled label="Причина отмены">
                  <SelectField
                    source={'cancelReason'}
                    label={'Причина отмены'}
                    choices={[
                      { id: 'changeOrder', name: 'Клиент хочет изменить заказ' },
                      { id: 'changeMyMind', name: 'Клиент передумал' },
                      { id: 'other', name: 'Другое' },
                    ]}
                  />
                </Labeled>
              )}
            </Grid>
            <Grid item md={3}>
              <Labeled label="Предыдущий статус">
                <SelectField source={'prevStatus'} label={'Статус'} choices={AllOrdersStatusesList} />
              </Labeled>
            </Grid>
            <Grid item md={3}>
              <Labeled label="Статус оплаты">
                <SelectField source={'paymentStatus'} label={'Статус оплаты'} choices={PaymentStatusList} />
              </Labeled>
            </Grid>
            <PaymentErrorDetailsField />
          </Grid>

          <Header>Доставка</Header>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Labeled label="Плановое">
                <DateField source={'exceptedDeliveryAt'} showTime={true} />
              </Labeled>
              <FunctionField
                source={'deliverTime'}
                render={(i: any) => (i.deliveryTime ? `${i.deliveryTime} мин.` : '')}
              />
            </Grid>
            <Grid item md={3}>
              <Labeled label="Фактическое">
                <DateField source={'deliveredAt'} showTime={true} />
              </Labeled>
            </Grid>

            <Grid item md={3}>
              <Labeled label="Опоздание">
                <FunctionField
                  source={'lateTime'}
                  render={(value: any) => (value.lateTime ? `${value.lateTime} мин` : '-')}
                />
              </Labeled>
            </Grid>
            <Grid item md={3}>
              <Labeled label="Бесконтактная доставка">
                <FunctionField
                  source={'isContactLessDelivery'}
                  render={(value: any) => (value.isContactLessDelivery ? 'Да' : '-')}
                />
              </Labeled>
            </Grid>

            <Grid item md={3}>
              <Labeled label="Время принятия">
                <DateField source={'confirmedAt'} showTime={true} />
              </Labeled>
            </Grid>
          </Grid>

          <Header>Клиент</Header>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Labeled label="Имя">
                <TextField source={'user.name'} />
              </Labeled>
            </Grid>
            <Grid item md={4}>
              <Labeled label="Телефон">
                <PhoneCallActionField source={'phone'} label={'Телефон'} />
              </Labeled>
            </Grid>
            <Grid item md={4}>
              <Labeled label="Email">
                <TextField source={'user.email'} />
              </Labeled>
            </Grid>
            <LinkField link={(order) => `/user/${order.userId}/show`} value={() => 'Карточка клиента'} />
          </Grid>
          <Header>Оплата</Header>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <Labeled label="Способ оплаты">
                <SelectField source={'paymentMethod'} choices={PaymentMethodList} />
              </Labeled>
            </Grid>
            <Grid item md={4}>
              <Labeled label="Способ доставки">
                <SelectField source={'deliveryMethod'} choices={DeliveryMethodList} />
              </Labeled>
            </Grid>
          </Grid>

          <Header>Адрес</Header>
          <Box mb={1}>
            <LinkExternalField
              source={'address.address'}
              link={(value: any) =>
                value.location
                  ? `https://yandex.ru/maps/?pt=${[value.location.lat, value.location.lng].reverse().join(',')}&z=18&l=map`
                  : ''
              }
            />
          </Box>
          <Grid container spacing={2}>
            <Grid item md={2}>
              <Labeled label="Кв/Офис">
                <TextField source={'address.apartment'} />
              </Labeled>
            </Grid>
            <Grid item md={2}>
              <Labeled label="Подьезд">
                <TextField source={'address.entrance'} />
              </Labeled>
            </Grid>
            <Grid item md={2}>
              <Labeled label="Этаж">
                <TextField source={'address.floor'} />
              </Labeled>
            </Grid>
            <Grid item md={3}>
              <Labeled label="Домофон">
                <TextField source={'address.intercomCode'} />
              </Labeled>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={12}>
              <Labeled label="Комментарий к адресу">
                <TextField source={'address.comment'} />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <OrderChanges />
    </>
  )
}

const Index = () => {
  return (
    <Show emptyWhileLoading actions={<Actions />}>
      <SimpleShowLayout>
        <OrderShow />
      </SimpleShowLayout>
    </Show>
  )
}
export default Index

import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  required,
  RadioButtonGroupInput,
  SelectInput,
  BooleanInput,
  SelectArrayInput,
  NumberInput,
  FormDataConsumer,
} from 'react-admin'
import { ChangeEventHandler } from 'react'
import { useFormContext } from 'react-hook-form'
import FormGroup from 'src/components/FormGroup'
import { FacilityTypeList } from 'src/types/enum/FacilityType'
import { DeliveryIntegrationTypeList } from 'src/types/enum/DeliveryIntegrationType'
import { PendingOrderType, PendingOrderTypeList } from 'src/types/enum/PendingOrderType'
import { DeliveryKindList } from 'src/types/enum/DeliveryKind'
import { PaymentMethodList } from 'src/types/enum/PaymentMethod'
import { UnitAreaList } from 'src/resources/unit/area/UnitAreaList'
import TimeSelectInput from 'src/components/inputs/TimeSelectInput'
import { UnitStatusList } from 'src/types/enum/UnitStatus'
import Formatter from 'src/utils/formatters'
import BrandInput from 'src/components/inputs/BrandInput'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { MultiEmailInput } from 'src/components/inputs/MultiEmailInput'
import CategoriesArrayInput from 'src/components/inputs/CategoriesArrayInput'
import { ServiceType } from 'src/types/enum/ServiceType'
import WorkScheduleMultiTimeInput from 'src/components/inputs/WorkScheduleMultiTimeInput'
import CountryInput from '../../components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'

const InfoTab = (props: any) => {
  const form = useFormContext()
  const formValues = form.watch()

  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
    form.setValue('innerName', e.currentTarget.value)
  }

  const handleApiChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.currentTarget.value) {
      form.setValue('apiSettings.apiSyncOrder', true)
      form.setValue('apiSettings.apiSyncOrderStatus', true)
      form.setValue('apiSettings.apiSyncMenu', true)
      form.setValue('apiSettings.apiSyncStopList', true)
      form.setValue('apiSettings.apiSyncZones', true)
    } else {
      form.setValue('apiSettings.apiSyncOrder', false)
      form.setValue('apiSettings.apiSyncOrderStatus', false)
      form.setValue('apiSettings.apiSyncMenu', false)
      form.setValue('apiSettings.apiSyncStopList', false)
      form.setValue('apiSettings.apiSyncZones', false)
    }
  }
  //
  return (
    <FormTab {...props} label={'Заведение'}>
      <FormGroup title={'Юридические данные'}>
        <TextInput
          source="name"
          label={'Название'}
          validate={[required()]}
          fullWidth
          onChange={handleNameChange}
          helperText={'Может выводиться в каталоге'}
        />
        <TextInput
          source="innerName"
          label={'Внутреннее название'}
          fullWidth
          onChange={handleNameChange}
          helperText={'Выводиться только в ЛК Партнера'}
        />
        <TextInput source="slug" label={'URI'} validate={[required()]} fullWidth />
        <IfCanAccess aggregator action={'edit'}>
          <SelectInput source={'status'} label={'Статус'} choices={UnitStatusList} validate={[required()]} />
        </IfCanAccess>

        <TextInput source="vendorCode" label={'Код у бренда'} fullWidth />
        <CountryInput
          source={'countryId'}
          label={'Страна'}
          allowEmpty={false}
          onChange={() => form.resetField('regionId')}
          fullWidth
          validate={required()}
        />
        <PartnerInput
          source={'partnerId'}
          label={'Партнер'}
          filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
          validate={[required()]}
        />
        <BrandInput source={'brandId'} label={'Бренд'} validate={[required()]} />
        <TextInput source="rating" label={'Рейтинг'} fullWidth />
        <RadioButtonGroupInput
          source="facilityType"
          label={'Тип заведения'}
          choices={FacilityTypeList}
          row={false}
          validate={[required()]}
        />
        <BooleanInput source="published" label={'Видимость'} fullWidth />
      </FormGroup>
      <FormGroup title={'Товарные разделы'}>
        <CategoriesArrayInput
          filter={{ serviceType: ServiceType.Delivery }}
          source={'categoriesIds'}
          label={'Выбранные разделы'}
        />
      </FormGroup>

      <FormGroup title={'Адрес'}>
        <ReferenceInput
          allowEmpty={false}
          source="regionId"
          reference="region"
          filterToQuery={(searchText: string) => ({ ...(searchText ? { 'name||$eq': searchText } : {}) })}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={100}
        >
          <AutocompleteInput optionText={(i) => `${i?.name || ''}`} label="Город" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="address" label={'Адрес'} validate={[required()]} fullWidth />
        <TextInput
          source="location"
          label={'Координаты'}
          validate={[required()]}
          helperText={'Через запуятую, например 55.616351,36.431444'}
          parse={(value) => {
            const parts = value.split(',')
            if (parts.length < 2 || !parts[0] || !parts[1]) {
              return value
            }
            return { lat: parts[0].trim(), lng: parts[1].trim() }
          }}
          format={(value) => {
            if (value?.lat && value.lng) {
              return `${value.lat},${value.lng}`
            }
            return value
          }}
          multiline={true}
          variant={'outlined'}
          fullWidth={true}
        />
      </FormGroup>
      <FormGroup title={'Контакты'}>
        <TextInput source="contactPerson" label={'Контактное лицо'} fullWidth />
        <TextInput source="phone" label={'Номер телефона'} fullWidth />
        <MultiEmailInput source={'contactEmails'} label={'Перечень email для уведомлений'} />
      </FormGroup>
      <FormGroup title={'API'}>
        <BooleanInput source="apiSettings.useApi" label={'Использовать API'} onChange={handleApiChange} />

        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) =>
            formData.apiSettings?.useApi && (
              <>
                <SelectInput
                  source="apiSettings.integrationType"
                  label={'Тип интеграции'}
                  choices={DeliveryIntegrationTypeList}
                  fullWidth
                />
                <TextInput source="apiSettings.deliverySettings.url" label={'Ссылка на каталог'} fullWidth />
                <TextInput source="apiSettings.deliverySettings.token" label={'Токен'} fullWidth />
                <TextInput source="apiSettings.deliverySettings.restaurantId" label={'ID ресторана'} fullWidth />
                <BooleanInput source="apiSettings.apiSendOrder" label={'Синхронизировать заказы'} fullWidth />
                <BooleanInput source="apiSettings.apiSyncOrderStatus" label={'Синхронизировать статус заказа'} />
                <BooleanInput source="apiSettings.apiSyncMenu" label={'Синхронизировать Меню'} />
                <BooleanInput source="apiSettings.apiSyncStopList" label={'Синхронизировать стоп лист'} />
                <BooleanInput source="apiSettings.apiSyncZones" label={'Синхронизировать зоны доставки'} />
              </>
            )
          }
        </FormDataConsumer>
      </FormGroup>

      <FormGroup title={'Заказ ко времени'}>
        <TimeSelectInput
          source="orderSettings.orderMinTime"
          label={'Миним. время отложенной доставки (ч.)'}
          fullWidth
          type={'minute'}
          step={1}
          max={12}
          validate={[required()]}
        />
        <TimeSelectInput
          source="orderSettings.orderMaxTime"
          label={'Макс. время отложенной доставки (дн.)'}
          fullWidth
          type={'day'}
          step={1}
          max={13}
          validate={[required()]}
        />

        <RadioButtonGroupInput
          source="apiSettings.pendingOrderType"
          label={'Отправлять отложенные заказы'}
          choices={PendingOrderTypeList}
          fullWidth
        />
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) =>
            formData?.apiSettings.pendingOrderType == PendingOrderType.InTime && (
              <NumberInput
                source="apiSettings.orderInTime"
                validate={[required()]}
                label={'За сколько минут отправлять'}
                fullWidth
              />
            )
          }
        </FormDataConsumer>
      </FormGroup>
      <FormGroup title={'Доставка'}>
        <RadioButtonGroupInput
          source="deliveryKind"
          label={false}
          validate={[required()]}
          choices={DeliveryKindList}
          row={false}
        />
        <BooleanInput source="hasTakeaway" label={'Возможен самовывоз'} fullWidth />
        <BooleanInput source="orderSettings.hasContactLessDelivery" label={'Есть бесконтактная доставка'} fullWidth />
      </FormGroup>
      <FormGroup title={'Оплата'}>
        <SelectArrayInput
          source={'paymentMethods'}
          label={'Методы оплаты'}
          choices={PaymentMethodList}
          fullWidth
          variant={'outlined'}
        />
        <BooleanInput
          source="hasPaidDelivery"
          label={'Платная доставка'}
          fullWidth
          helperText={
            'В случае если клиент покупает товаров меньше чем на минимальную сумму заказа, то он самостоятельно доплачивает разницу.'
          }
        />
      </FormGroup>

      <FormGroup title={'Режим работы'}>
        <WorkScheduleMultiTimeInput source={'schedule'} label={false} />
      </FormGroup>

      <FormGroup title={'Параметры доставки'}>
        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) => (
            <NumberInput
              source="cookingTime"
              label={'Время приготовления (мин.)'}
              fullWidth
              validate={formData.hasTakeaway ? required() : undefined}
            />
          )}
        </FormDataConsumer>
        <BooleanInput
          source="cookingSettings.isCookingTimeIncrease"
          label={'Есть рост времение доставки в зависимост от суммы заказа'}
          fullWidth
          variant={'outlined'}
          validate={[required()]}
        />

        <FormDataConsumer fullWidth={true} variant={'outlined'}>
          {({ formData }) =>
            formData.cookingSettings?.isCookingTimeIncrease && (
              <>
                <NumberInput
                  source="cookingSettings.startAmount"
                  label={'Сумма после которой прибавлять время '}
                  fullWidth
                />
                <NumberInput source="cookingSettings.stepAmount" label={'Шаг суммы'} fullWidth />
                <NumberInput source="cookingSettings.stepTime" label={'Шаг времени'} fullWidth />
              </>
            )
          }
        </FormDataConsumer>

        <TimeSelectInput
          source="stopAcceptOrderTime"
          label={'Не принимать заказы за n минут до закрытия'}
          fullWidth
          type={'minute'}
          step={5}
          max={120}
        />
      </FormGroup>
    </FormTab>
  )
}

const AreaTab = (props: any) => {
  return (
    <FormTab {...props} label={'Зоны доставки'}>
      <UnitAreaList {...props} />
    </FormTab>
  )
}

const UnitForm = (props: any) => {
  return (
    <TabbedForm
      defaultValues={{
        apiSettings: {
          pendingOrderType: PendingOrderType.RightAway,
          deliverySettings: {},
        },
        orderSettings: {
          orderMinTime: 1,
          orderMaxTime: 1,
        },
      }}
    >
      <InfoTab />
      {props.isEdit && <AreaTab />}
    </TabbedForm>
  )
}
export default UnitForm

import * as React from 'react'
import {
  TextInput,
  required,
  RadioButtonGroupInput,
  SimpleForm,
  minLength,
  maxLength,
  ReferenceManyField,
  Pagination,
  DateField,
  TextField,
} from 'react-admin'
import * as _ from 'lodash'
import FormGroup from 'src/components/FormGroup'
import { PartnerStatus, PartnerStatusList } from 'src/types/enum/PartnerStatus'
import { MultiEmailInput } from 'src/components/inputs/MultiEmailInput'
import InnInput, { IInnInputCompany } from 'src/components/inputs/InnInput'
import { useFormContext } from 'react-hook-form'
import RelatedList from 'src/components/list/RelatedList'
import { useRecordContext } from 'ra-core'
import PartnerTariffDescriptionField from 'src/components/fields/PartnerTariffDescriptionField'
import CountryInput from '../../components/inputs/CountryInput'
import { isDandbProject } from '../../components/list/tree/utils'
import { useCallback } from 'react'

type Props = {
  isCreation: boolean
}

const PartnerTariffs = () => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="tariff"
      target="partnerId"
      sort={{ field: 'updatedAt', order: 'DESC' }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={'tariff'}
        hasCreate={false}
        emptyTitle={'У партнера нет идивидуальных тарифов'}
        bulkActionButtons={false}
        rowClick={'edit'}
      >
        <TextField source={'name'} label={'Название'} />
        <PartnerTariffDescriptionField source={'descriptions'} label={'Настройки'} />
        <TextField source={'promo.name'} label={'Акция'} />
        <DateField source="createdAt" label={'Время создания'} showTime={true} />
        <DateField source="updatedAt" label={'Время обновления'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>
  )
}

const InfoTab = (props: Props) => {
  const form = useFormContext()
  const formValues = form.watch()

  const handleChangeInn = (company: IInnInputCompany | null) => {
    if (!company) {
      return
    }
    if (company?.kpp) {
      form.setValue('legalKpp', company.kpp)
    }
    if (company?.name) {
      form.setValue('name', company.name)
    }
    if (company?.address) {
      form.setValue('legalAddress', company.address)
    }
    if (company?.ogrn) {
      form.setValue('legalOgrn', company.ogrn)
    }
    if (company?.okpo) {
      form.setValue('legalOkpo', company.okpo)
    }
    if (company?.legalType) {
      form.setValue('legalType', company.legalType)
    }
  }

  const buildLegalDataFields = useCallback(() => {
    if (isDandbProject()) {
      const countryId = +formValues.countryId

      if (!_.isNumber(countryId) || countryId === 0) {
        return <></>
      }
      const isKazakhstan = countryId === 3
      //
      return (
        <>
          <TextInput
            name="legalInn"
            source={isKazakhstan ? 'legalData.legalBin' : 'legalData.legalInn'}
            label={isKazakhstan ? 'БИН' : 'ИНН'}
            fullWidth
            validate={[required('Обязательно для заполнения'), minLength(7, 'Минимальное кол-во 7')]}
          />
          <TextInput
            name="name"
            source="name"
            label={isKazakhstan ? 'Юридическое лицо ТОО/ИП' : 'Юридическое лицо'}
            validate={[required()]}
            fullWidth
          />
          <TextInput
            name="legalData.legalAddress"
            source="legalData.legalAddress"
            label={'Юридический адрес'}
            fullWidth
          />
          {!isKazakhstan && (
            <>
              <TextInput name="legalData.legalBik" source="legalData.legalBik" label={'БИК'} fullWidth />
              <TextInput name="legalData.legalKpp" source="legalData.legalKpp" label={'КПП'} fullWidth />
              <TextInput name="legalData.legalOgrn" source="legalData.legalOgrn" label={'ОГРН/ИП'} fullWidth />
              <TextInput name="legalData.legalOkpo" source="legalData.legalOkpo" label={'ОКПО'} fullWidth />
            </>
          )}
          {/* Bank details */}
          <TextInput name={'legalData.bankBik'} source="blegalData.bankBik" label={'БИК Банка'} fullWidth />
          <TextInput name={'legalData.bank'} source="legalData.bank" label={'Банк'} fullWidth />
          <TextInput
            name={isKazakhstan ? 'legalData.bankIik' : 'legalData.bankAccount'}
            source={isKazakhstan ? 'legalData.bankIik' : 'legalData.bankAccount'}
            label={isKazakhstan ? 'Расчетный счет (ИИК)' : 'Расчетный счет'}
            fullWidth
          />
          {isKazakhstan ? (
            <TextInput name={'legalData.director'} source="legalData.director" label={'Директор'} fullWidth />
          ) : (
            <>
              <TextInput
                name={'legalData.bankCorrespondentAccount'}
                source="legalData.bankCorrespondentAccount"
                label={'Корреспондентский счет банка'}
                fullWidth
              />
              <TextInput name={'legalData.bankInn'} source="legalData.bankInn" label={'ИНН Банка'} fullWidth />
              <TextInput
                name={'legalData.bankLegalAddress'}
                source="legalData.bankLegalAddress"
                label={'Юридический адрес банка'}
                fullWidth
              />
            </>
          )}
        </>
      )
    }
    return (
      <>
        <InnInput
          name="legalData.legalInn"
          source="legalData.legalInn"
          label={'ИНН'}
          fullWidth
          onChange={handleChangeInn}
          validate={[
            required('Обязательно для заполнения'),
            minLength(10, 'Минимальное кол-во 10'),
            maxLength(12, 'Максимальное кол-во 12'),
          ]}
        />
        <TextInput name="name" source="name" label={'Юридическое лицо'} validate={[required()]} fullWidth />
        <TextInput
          name="legalData.legalAddress"
          source="legalData.legalAddress"
          label={'Юридический адрес'}
          fullWidth
        />
        <TextInput name="legalData.legalBik" source="legalData.legalBik" label={'БИК'} fullWidth />
        <TextInput name="legalData.legalKpp" source="legalData.legalKpp" label={'КПП'} fullWidth />
        <TextInput name="legalData.legalOgrn" source="legalData.legalOgrn" label={'ОГРН/ИП'} fullWidth />
        <TextInput name="legalData.legalOkpo" source="legalData.legalOkpo" label={'ОКПО'} fullWidth />
        {/* Bank details */}
        <TextInput name={'legalData.bankBik'} source="legalData.bankBik" label={'БИК Банка'} fullWidth />
        <TextInput name={'legalData.bank'} source="legalData.bank" label={'Банк'} fullWidth />
        <TextInput name={'legalData.bankAccount'} source="legalData.bankAccount" label={'Расчетный счет'} fullWidth />
        <TextInput
          name={'legalData.bankCorrespondentAccount'}
          source="legalData.bankCorrespondentAccount"
          label={'Корреспондентский счет банка'}
          fullWidth
        />
        <TextInput name={'legalData.bankInn'} source="legalData.bankInn" label={'ИНН Банка'} fullWidth />
        <TextInput
          name={'legalData.bankLegalAddress'}
          source="legalData.bankLegalAddress"
          label={'Юридический адрес банка'}
          fullWidth
        />
      </>
    )
  }, [formValues.countryId])

  return (
    <>
      <FormGroup title={'Юридические данные'}>
        {/* Partner details */}
        <CountryInput source={'countryId'} label={'Страна'} validate={[required()]} />

        {buildLegalDataFields()}
      </FormGroup>
      <FormGroup title={'Контакты'}>
        <TextInput name={'contactPerson'} source="contactPerson" label={'Контактное лицо'} fullWidth />
        <TextInput name={'phone'} source="phone" label={'Номер телефона'} fullWidth />
        <MultiEmailInput source={'contactEmails'} label={'Перечень email для уведомлений'} />
      </FormGroup>

      <FormGroup title={'Статус'}>
        <RadioButtonGroupInput source="status" label={false} choices={PartnerStatusList} row={false} />
      </FormGroup>
      {!props.isCreation && <PartnerTariffs />}
    </>
  )
}

const PartnerForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ status: PartnerStatus.Draft }}>
      <InfoTab isCreation={props.isCreation} />
    </SimpleForm>
  )
}

export default PartnerForm

import * as React from 'react'
import { useRecordContext } from 'ra-core'
import { DateField, Pagination, ReferenceManyField } from 'react-admin'
import RelatedList from '../list/RelatedList'
import RecordChangedDataField, { AdditionalMap } from './RecordChangeDataField'
import RecordChangedWhatField from './RecordChangedWhatField'

type Props = {
  sort?: { field: string; order: 'DESC' | 'ASC' }
  recordType: RecordType
  statusList?: { id: string; name: string }[]
  additionalMap?: AdditionalMap[]
  additionalAssetsFields?: string[]
}

export enum RecordType {
  Restaurant = 'restaurant',
  Promo = 'promo',
  PromoRestaurant = 'promoRestaurant',
  PromoUnit = 'promoUnit',
  PromoUnitGroup = 'promoUnitGroup',
  Tariff = 'tariff',
}

export const RecordChanges = (props: Props) => {
  const record = useRecordContext()

  return (
    <ReferenceManyField
      reference="record-change"
      target="id"
      sort={props.sort || { field: 'createdAt', order: 'DESC' }}
      filter={{ type: props.recordType }}
      pagination={<Pagination />}
      perPage={50}
    >
      <RelatedList
        record={record}
        resource={props.recordType}
        hasCreate={false}
        emptyTitle={'Пока нет изменений'}
        bulkActionButtons={false}
      >
        <RecordChangedWhatField source={'source'} label={''} />
        <RecordChangedDataField
          source={'before'}
          label={'До'}
          statusList={props.statusList}
          additionalMap={props.additionalMap}
          additionalAssetsFields={props.additionalAssetsFields}
        />
        <RecordChangedDataField
          source={'after'}
          label={'После'}
          statusList={props.statusList}
          additionalMap={props.additionalMap}
          additionalAssetsFields={props.additionalAssetsFields}
        />
        <DateField source="createdAt" label={'Время'} showTime={true} />
      </RelatedList>
    </ReferenceManyField>
  )
}

import * as _ from 'lodash'
import { UnitStatusList } from '../../types/enum/UnitStatus'
import { RecordChanges, RecordType } from '../RecordChangeList'
import { PromoKindList } from '../../types/enum/PromoKind'
import { PromoTypeList } from '../../types/enum/PromoType'
import { PromoPayerList } from '../../types/enum/PromoPayer'
import { DiscountTypeList } from '../../types/enum/DiscountType'
import { PromoGroupList } from '../../types/enum/PromoGroup'
import { PromoCodeTypeList } from '../../types/enum/PromoCodeType'
import { PromoIconList } from '../../types/enum/PromoIcon'
import { PromoTemplateList } from '../../types/enum/PromoTemplate'
import { PromoTextColorList } from '../../types/enum/PromoColor'
import Formatter from '../../utils/formatters'

export const PromoRecordChanges = () => {
  return (
    <RecordChanges
      recordType={RecordType.Promo}
      statusList={UnitStatusList}
      additionalAssetsFields={[
        'image',
        'imageAdded',
        'imageRemoved',
        'imageLarge',
        'imageLargeAdded',
        'imageLargeRemoved',
      ]}
      additionalMap={[
        { key: 'kind', label: 'Вид', choices: PromoKindList },
        { key: 'type', label: 'Тип', choices: PromoTypeList },
        { key: 'payer', label: 'Плательщик', choices: PromoPayerList },
        { key: 'promoCode', label: 'Промокод' },
        { key: 'region', label: 'Регион' },
        { key: 'name', label: 'Название' },
        { key: 'innerName', label: 'Внутреннее название' },
        { key: 'badge', label: 'Подпись' },
        { key: 'bigText', label: 'Большой текст' },
        { key: 'shortDesc', label: 'Короткое описание' },
        { key: 'description', label: 'Описание' },
        { key: 'published', label: 'Активен' },
        { key: 'sort', label: 'Сортировка' },
        { key: 'nOrder', label: 'Значение N заказа' },
        { key: 'discountType', label: 'Тип скидки', choices: DiscountTypeList },
        { key: 'discountValue', label: 'Фиксированная сумма / Процент от заказа' },
        { key: 'group', label: 'Группа', choices: PromoGroupList },
        { key: 'promoCodeType', label: 'Тип промокода', choices: PromoCodeTypeList },
        { key: 'icon', label: 'Вид акции', choices: PromoIconList },
        { key: 'color', label: 'Цвет' },
        { key: 'template', label: 'Шаблон баннера', choices: PromoTemplateList },
        { key: 'image', label: 'Изображение' },
        { key: 'textColor', label: 'Цвет текста', choices: PromoTextColorList },
        { key: 'badgeColor', label: 'Цвет бейджа', choices: PromoTextColorList },
        { key: 'badgeTextColor', label: 'Цвет текста', choices: PromoTextColorList },
        { key: 'startsAt', label: 'Начало действия', isDate: true },
        { key: 'endsAt', label: 'Окончание действия', isDate: true },
        { key: 'sort', label: 'Сортировка' },
        { key: 'image', label: 'Картинка' },
        { key: 'imageAdded', label: 'Картинка добавлена' },
        { key: 'imageRemoved', label: 'Картинка удалена' },
        { key: 'imageLarge', label: 'Большая картинка' },
        { key: 'imageLargeAdded', label: 'Большая картинка добавлена' },
        { key: 'imageLargeRemoved', label: 'Большая картинка удалена' },
        {
          key: 'discountByOrder',
          label: 'Значение скидки в зависимости от суммы заказа',
          valueBuilder: (values): string => {
            if (!_.isArray(values)) {
              return 'Unknown value'
            }
            return values
              .map((el, idx) => {
                let text = ''

                if (_.isNumber(el.order)) {
                  text += `Заказ: ${el.order}.`
                }
                if (_.isNumber(el.value)) {
                  text += ` Скидка: ${el.value}.`
                }
                if (idx !== 0) {
                  text += '\n'
                }
                return text.trim()
              })
              .join('\n')
          },
        },
        {
          key: 'categories',
          label: 'Товарные разделы',
          valueBuilder: (value): string => {
            if (Array.isArray(value)) {
              return value.map((el) => el.name).join(', ')
            }
            return value
          },
        },
        {
          key: 'settings',
          label: 'Условия',
          valueBuilder: (value): string => {
            if (!_.isPlainObject(value)) {
              return 'Unknown value'
            }
            let text = ''

            for (const key in value) {
              switch (key) {
                case 'canExcludeCategories':
                  text += `\n- Партнер может исключать категории агрегатора: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'canIncludeCategories':
                  text += `\n- Партнер может выбирать Товарные разделы агрегатора: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'canExcludeProductCategories':
                  text += `\n- Партнер может исключать категории своего меню: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'canIncludeProductCategories':
                  text += `\n- Партнер может выбирать категории своего меню: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'canIncludeProducts':
                  text += `\n- Партнер может выбирать блюда: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'canExcludeProducts':
                  text += `\n- Партнер может исключать блюда: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'dontCheckMinimumAmount':
                  text += `\n- Не проверять минимальную сумму заказа: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'includeDeliveryPriceIntoPromoFixedPriceGift':
                  text += `\n- Включить сумму доставки в сумму акции "Сумма в подарок: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'minProductsCount':
                  text += `\n- Минимальное кол-во блюд: ${value[key]}`
                  break
                case 'canChangeOrderAmount':
                  text += `\n- Партнер может менять минимальную сумму заказа: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'minOrderAmount':
                  text += `\n- Минимальная сумма заказа: ${value[key]}`
                  break
                case 'canSchedule':
                  text += `\n- Может использовать расписание: ${Formatter.convertBooleanToYesOrNoText(value[key])}`
                  break
                case 'perCustomerUsageLimit':
                  text += `\n- Максимальное кол-во заказов клиента: ${value[key]}`
                  break
              }
            }
            return text.trim()
          },
        },
      ]}
    />
  )
}

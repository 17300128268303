import * as React from 'react'
import * as _ from 'lodash'
import { useRecordContext } from 'ra-core'
import { PromoUnitStatus } from '../../types/enum/PromoUnitStatus'
import { Typography } from '@mui/material'
import moment from 'moment/moment'

export const ActivePeriod = (props: { isCreation?: boolean }) => {
  const record = useRecordContext()
  //
  if (props.isCreation || record.status !== PromoUnitStatus.Active || _.isNil(record.lastActivatedAt)) {
    return null
  }
  return (
    <Typography sx={{ mb: 2 }}>
      Текущий активный период (день): {Math.abs(moment(new Date()).diff(record.lastActivatedAt, 'days'))}
    </Typography>
  )
}

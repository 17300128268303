import * as React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  BooleanInput,
  DateTimeInput,
  FormDataConsumer,
  minValue,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  useGetIdentity,
  useGetOne,
  useResourceContext,
} from 'react-admin'
import { useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import { IPromo } from 'src/types/interfaces/IPromo'
import PartnerInput from 'src/components/inputs/PartnerInput'
import { PromoUnitStatus, PromoUnitStatusList } from 'src/types/enum/PromoUnitStatus'
import { IfCanAccess } from 'src/components/permissions/IfCanAccess'
import WorkScheduleMultiTimeInput from 'src/components/inputs/WorkScheduleMultiTimeInput'
import RestaurantInput from 'src/components/inputs/RestaurantInput'
import PromoServiceRestaurantsSelectPageInput from 'src/components/inputs/PromoServiceRestaurantsSelectPageInput'
import PromoCardWithDetails from 'src/components/Promo/PromoCardWithDetails'
import { AdminRole, AggregatorAdminRoles } from 'src/types/enum/AdminRole'
import { ServiceType } from 'src/types/enum/ServiceType'
import { RecordChanges, RecordType } from 'src/components/RecordChangeList'
import { PromotionType, PromotionTypeList } from '../../types/enum/PromotionType'
import Formatter from '../../utils/formatters'
import CountryInput from '../../components/inputs/CountryInput'
import { getCountryIdFilterBasedOnProject, isDandbProject } from '../../components/list/tree/utils'
import { ActivePeriod } from 'src/components/ActivePeriod'

interface DetailsProps {
  serviceType: ServiceType
  isCreation: boolean
}

const Details = (props: DetailsProps) => {
  const form = useFormContext()
  const { data: promo } = useGetOne<IPromo>(`promo-service-${props.serviceType}-public`, {
    id: form.getValues('promoId'),
  })

  return (
    <Box>
      <IfCanAccess aggregator action={'show'}>
        <SelectInput source={'status'} label={'Статус'} choices={PromoUnitStatusList} />
      </IfCanAccess>

      <SelectInput source="promotionType" label="Тип акции" choices={PromotionTypeList} validate={required()} />
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          formData.promotionType === PromotionType.Automatic && (
            <NumberInput
              source={'priority'}
              label={'Приоритет'}
              helperText={'Приоритет показа с меньшего к большему'}
              validate={[required(), minValue(1)]}
            />
          )
        }
      </FormDataConsumer>

      {promo && <PromoCardWithDetails promo={promo} />}

      <DateTimeInput source="startsAt" label="Время начала действия" variant={'outlined'} />
      <DateTimeInput source="endsAt" label="Время окончания действия" variant={'outlined'} />
      <ActivePeriod isCreation={props.isCreation} />

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {() => promo?.settings.canSchedule && <BooleanInput source="hasSchedule" label={'Есть расписание'} fullWidth />}
      </FormDataConsumer>

      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData }) =>
          promo?.settings.canSchedule &&
          formData.hasSchedule && (
            <WorkScheduleMultiTimeInput
              source={'schedule'}
              label={'Расписание'}
              withLimit={formData.promotionType === PromotionType.Automatic}
            />
          )
        }
      </FormDataConsumer>
    </Box>
  )
}

enum FormStep {
  Partner = 'partner',
  Unit = 'unit',
  Promo = 'promo',
  Details = 'details',
}

const PromoTab = (props: DetailsProps) => {
  const form = useFormContext()
  const formValues = form.watch()
  const { data } = useGetIdentity()
  //
  const [step, setStep] = useState<FormStep>(form.getValues('promoId') ? FormStep.Details : FormStep.Partner)
  const stepRef = useRef<FormStep>(step)

  useEffect(() => {
    if (data?.role && !AggregatorAdminRoles.includes(data?.role as AdminRole)) {
      if (stepRef.current === FormStep.Partner) {
        setStep(FormStep.Unit)
      }
    }
  }, [data?.role])

  const handleChangePromo = (_: IPromo) => {
    setStep(FormStep.Details)
  }

  const handleChangeRestaurant = (val: number) => {
    setStep(FormStep.Promo)
    form.setValue('countryId', formValues.countryId)
    form.setValue('partnerId', formValues.partnerId)
    form.setValue('restaurantId', val)
    form.setValue('promoId', null)
  }

  const handleChangePartner = (val: number) => {
    setStep(FormStep.Unit)
    form.reset()
    form.setValue('countryId', formValues.countryId)
    form.setValue('partnerId', val)
    form.setValue('promoId', null)
    form.setValue('restaurantId', null)
  }

  const handleChangeCountry = (val: string) => {
    setStep(FormStep.Partner)
    form.reset()
    form.setValue('countryId', val)
    form.setValue('partnerId', null)
    form.setValue('promoId', null)
    form.setValue('unitId', null)
  }

  return (
    <>
      <CountryInput
        source={'countryId'}
        label={'Страна'}
        allowEmpty={false}
        onChange={handleChangeCountry}
        fullWidth
        validate={required()}
      />

      {(formValues.countryId || !isDandbProject()) && (
        <IfCanAccess aggregator action={'show'}>
          <PartnerInput
            resettable
            fullWidth
            source="partnerId"
            label={'Партнер'}
            filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
            onChange={handleChangePartner}
            validate={required()}
          />
        </IfCanAccess>
      )}
      {[FormStep.Promo, FormStep.Unit, FormStep.Details].includes(step) && (
        <RestaurantInput
          source={'restaurantId'}
          serviceType={props.serviceType}
          fullWidth
          resettable={false}
          allowEmpty={false}
          onChange={handleChangeRestaurant}
          filter={{ ...(formValues.partnerId ? { partnerId: formValues.partnerId } : {}) }}
          validate={[required()]}
        />
      )}

      {step == FormStep.Promo && (
        <PromoServiceRestaurantsSelectPageInput
          serviceType={props.serviceType}
          source={'promoId'}
          onChange={handleChangePromo}
          filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
        />
      )}
      {step == FormStep.Details && <Details {...props} />}
      {!props.isCreation && (
        <RecordChanges
          recordType={RecordType.PromoRestaurant}
          statusList={PromoUnitStatusList}
          additionalMap={[
            { key: 'promotionType', label: 'Тип акции', choices: PromotionTypeList },
            { key: 'priority', label: 'Приоритет' },
            { key: 'startsAt', label: 'Время начала действия', isDate: true },
            { key: 'endsAt', label: 'Время окончания действия', isDate: true },
            { key: 'lastActivePeriod', label: 'Активный период акции (день)' },
            {
              key: 'schedule',
              label: 'Расписание',
              valueBuilder: Formatter.formatScheduleForRender,
            },
          ]}
        />
      )}
    </>
  )
}

const PromoRestaurantForm = (props: any) => {
  const resource = useResourceContext()

  const serviceType = useMemo<ServiceType>(() => {
    if (resource === 'promo-restaurant') {
      return ServiceType.Restaurants
    }
    return resource.replace('promo-restaurant-', '').replace('promo-', '') as ServiceType
  }, [resource])

  return (
    <SimpleForm {...props} redirect={'list'} defaultValues={{ status: PromoUnitStatus.Active }}>
      <PromoTab serviceType={serviceType} isCreation={props.isCreation} />
    </SimpleForm>
  )
}

export default PromoRestaurantForm

import * as React from 'react'
import * as _ from 'lodash'
import {
  SimpleForm,
  required,
  SelectInput,
  TextInput,
  Toolbar,
  SaveButton,
  BooleanInput,
  DateTimeInput,
} from 'react-admin'
import { ApplicationItemList } from 'src/types/enum/Application'
import { AlertTypeItemList } from 'src/types/enum/AlertType'
import RegionInput from 'src/components/inputs/ReqionInput'
import CountryInput from '../../components/inputs/CountryInput'
import { useFormContext } from 'react-hook-form'
import { getCountryIdFilterBasedOnProject } from '../../components/list/tree/utils'
import { ServiceType, ServiceTypeList } from '../../types/enum/ServiceType'

const Form = (props: any) => {
  const form = useFormContext()
  const formValues = form.watch()

  return (
    <>
      <TextInput
        source={'name'}
        label={'Название алерта (для идентификации в админке)'}
        fullWidth
        validate={required()}
      />
      <TextInput source={'title'} label={'Заголовок'} fullWidth />
      <TextInput source={'description'} label={'Текст'} fullWidth />
      <SelectInput
        source={'application'}
        label={'Приложение'}
        choices={ApplicationItemList}
        fullWidth
        disabled={props.isEditMode}
        validate={required()}
      />
      <SelectInput
        source={'serviceType'}
        label={'Сервис'}
        helperText={
          'Если не указать тип - алерт будет глобальным без надобности открыть какую либо конкретную секцию в приложении'
        }
        choices={ServiceTypeList}
        resettable
        fullWidth
      />
      <SelectInput source={'type'} label={'Тип алерта'} choices={AlertTypeItemList} fullWidth validate={required()} />
      <CountryInput
        source={'countryId'}
        label={'Страна'}
        allowEmpty={false}
        onChange={() => form.resetField('regionId')}
        fullWidth
        validate={required()}
      />
      {!formValues.allRegions && (
        <RegionInput
          source={'regionId'}
          label={'Город'}
          allowEmpty={false}
          filter={getCountryIdFilterBasedOnProject(formValues.countryId)}
          fullWidth
          validate={required()}
        />
      )}
      <DateTimeInput source={'startAt'} label={'Дата начала действия'} />
      <DateTimeInput source={'endAt'} label={'Дата окончания действия'} />
      {(_.isNil(formValues.serviceType) ||
        formValues.serviceType === ServiceType.Delivery ||
        formValues.serviceType === ServiceType.Takeaway) && (
        <BooleanInput
          source="blockOrders"
          label={'Блокировать заказы доставки'}
          variant={'outlined'}
          validate={required()}
        />
      )}
      <BooleanInput
        source="allRegions"
        label={'Показывать во всех регионах'}
        variant={'outlined'}
        validate={required()}
      />
      <BooleanInput source={'active'} label={'Активен'} validate={required()} />
    </>
  )
}

export const AlertForm = (props: any) => {
  return (
    <SimpleForm
      {...props}
      redirect={'list'}
      toolbar={
        <Toolbar {...props}>
          <SaveButton />
        </Toolbar>
      }
    >
      <Form isEditMode={props.isEditMode} />
    </SimpleForm>
  )
}
